.container{
    background-color: white;
    margin-top: 5px;
    margin-bottom: 5px;

}
.forecastCard{
    display: flex;
    justify-content: space-around;
   
}
.oneCard{
    border-right: 1px solid rgb(230, 230, 230);
    width: 100%;
}
.oneCard_last{
    border-right: 1px solid rgb(255, 255, 255);
    width: 100%;
}

.temp1{
    display: flex;
    align-items: center;
    place-content: center;
}
.temp2{
    display: flex;
    align-items: center;
    margin-top: -30px;
    place-content: center; 
}

.icon{ 
    width: 35px;
    height: 35px;
    border-radius: 5px;
    margin-bottom: -10px;
}
.titleTime{
    margin-top: 20px;
    font-size: 16px;
    font-weight: 500;
    color: #4FA2CD;
}
.tempTitleMax{
    font-size: 11px;
    font-weight: 500;
    color: black;
}
.tempTitleMin{
    font-size: 11px;
    font-weight: 500;
    color: silver;
}
.tempValueMax{
    font-size: 18px;
    font-weight: 500;
    color: black;
    margin-left: 5px;
}
.tempValueMin{
    font-size: 18px;
    font-weight: 500;
    color: silver;
    margin-left: 8px;
}

.predictability_div{ 
    text-align: center;
    margin-bottom:0px;
    justify-content: space-evenly;
    margin: 1px 20px;
}
.predictability_tooltip{ 
    font-size: 10px;
    color: silver; 
    margin: 0px;
    margin-top: 2px;
}
.precipitation_div { 
    display: flex;
    place-content: center;
    align-items: center;
    margin-top: 16px;
}
.rain_icon { 
    width: 20px;
    height: 20px; 
    border-radius: 5px;
    text-align: center;
 }
.precipitation_probability {
    font-size: 14px;
    margin: 0px;
    margin-left: 5px;
 }
.wind_div { 
    margin-top: 16px;
    align-items: center;
    place-content: center;
    text-align: center;
}
.wind_degrees { 
    font-size: 14px;
    margin: 0px;
}
.wind_speed { 
    font-size: 12px;
    margin: 0px;
    margin-bottom: 3px;
    font-weight: 500;
}
.humidity { 
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0px;
    place-content: center;
    text-align: center;
}
.tooltip { 
    font-size: 12px;
    color: silver;
    margin: 0px;
    margin-top: 2px;
    margin-bottom: 15px;
    text-align: center;
}
.pressure { 
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 0px;
    place-content: center;
    text-align: center;
}
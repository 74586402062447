.OpstinaLandingNew_container__fhk-U{
    background-color: #f7f7f7;
}
.OpstinaLandingNew_title__18uT3{
    font-size: 16px;
    margin-left: 40px;
    padding-top:10px;
    font-weight: 400;
    color: darkgrey;
    text-align: center;
   
}
.OpstinaLandingNew_featuredRow__3IfWH{
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #f7f7f7;
}
.OpstinaLandingNew_featuredItem__3YGb1{
    flex: 1 1;
    margin: 5px 30px;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(154, 160, 185, 0.2), 0 15px 40px rgba(166, 173, 201, 0.1);
    background-color: white !important;
    
}
.OpstinaLandingNew_feturedTop__2IIKa{
    width: 100%;
    display: flex;
    justify-content: space-between;

}
.OpstinaLandingNew_featuredTitle__29UlH{
    font-size: 16px;
    font-weight: 400;
    color: grey;
}
.OpstinaLandingNew_featuredContainer__W-USn{
    margin: 5px 0px;
    flex:1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid darkgrey;
}
.OpstinaLandingNew_featuredNumber__29oBP{
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 5px;
}
.OpstinaLandingNew_featuredSub__2rAt7{
    font-size: 11px;
    color: grey;
}
.OpstinaLandingNew_featuredSub2__GbUW6{
    font-size: 11px;
    color: black;
    font-weight: 500;
    margin-left: 3px;
}

.OpstinaLandingNew_featuredContainerBottom__1FTj3{
    margin: 10px 20px;
    display: flex;
   
}
.OpstinaLandingNew_featuredItemBottom1__3f8uw{
    margin: 20px 10px;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(154, 160, 185, 0.2), 0 15px 40px rgba(166, 173, 201, 0.1);
    background-color: white !important;
    height:500px;
    overflow:scroll;
    width:58%;
    
}
.OpstinaLandingNew_featuredItemBottom2__168A0{
    margin: 20px 10px;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(154, 160, 185, 0.2), 0 15px 40px rgba(166, 173, 201, 0.1);
    background-color: white !important;
    height:500px;
    overflow:scroll;
    width:41%;
    
}

.OpstinaLandingNew_seedingRow__bYwpg{
    background-color: white;
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;
    align-items: center;
 
}

.OpstinaLandingNew_content_container__uHbyi {
    margin-top: 8px;
}

.OpstinaLandingNew_content_border__18GkS {
    background: #4FA2CD;
    border-radius: 20px;
    width: 100%;
    height: 2px;
}
.OpstinaLandingNew_table__16N6p {
    width: 100%;
    border-collapse: collapse;
    display: table;
}

.OpstinaLandingNew_table_header__VN6Ae {
    
    font-size: 12px;
    font-weight: 500;
    color: #4FA2CD;
    text-align: left;
    padding: 8px 0px 8px 8px;
    display: table-cell;
}

.OpstinaLandingNew_table_row__8Cmny {
    border-bottom: solid 1px rgba(0, 0, 0, .05);
    display: table-row;
}

.OpstinaLandingNew_table_border__11o9O {
    background: #9c9c9c;
    border-radius: 20px;
    width: 100%;
    height: 2px;
}
.OpstinaLandingNew_table_row_text__1ReT6 {
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #333;
    text-align: left;
    padding: 8px 0px 8px 8px;
    display: table-cell;
}
.OpstinaLandingNew_table_row_text_bold__K_j9N {
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: -0.3px;
    color: black;
    text-align: left;
    padding: 8px 0px 8px 8px;
    display: table-cell;
    font-weight: 500;
}

.OpstinaLandingNew_table_row_odd__3mqAn {
    border-bottom: solid 1px rgba(0, 0, 0, .07);
    background: #f8f8f8;
    display: table-row;
}

.OpstinaLandingNew_table_body__3YHa6 {
    display: block;
    overflow: auto;
    height: 400px;
    width: 100%;
}

.OpstinaLandingNew_table_head__2ilxb {
    display: table-header-group;
    vertical-align: middle;
    overflow: hidden;
}

.OpstinaLandingNew_table_row_header__pExy- {
    border-bottom: solid 1px rgba(0, 0, 0, .07);
    display: block;
}

.OpstinaLandingNew_table_div__2y_ie {
    overflow-y: auto;
    max-height: 380px;
    transition: height 0.2s;
}

.OpstinaWeather_container__1Jexi{
    background-color: white;
    margin-top: 5px;
    margin-bottom: 5px;

}
.OpstinaWeather_forecastCard__38cyC{
    display: flex;
    justify-content: space-around;
   
}
.OpstinaWeather_oneCard__1USsF{
    border-right: 1px solid rgb(230, 230, 230);
    width: 100%;
}
.OpstinaWeather_oneCard_last__3rgGm{
    border-right: 1px solid rgb(255, 255, 255);
    width: 100%;
}

.OpstinaWeather_temp1__7LwvS{
    display: flex;
    align-items: center;
    place-content: center;
}
.OpstinaWeather_temp2__1GmHq{
    display: flex;
    align-items: center;
    margin-top: -30px;
    place-content: center; 
}

.OpstinaWeather_icon__jgZEo{ 
    width: 35px;
    height: 35px;
    border-radius: 5px;
    margin-bottom: -10px;
}
.OpstinaWeather_titleTime__3U-h-{
    margin-top: 20px;
    font-size: 16px;
    font-weight: 500;
    color: #4FA2CD;
}
.OpstinaWeather_tempTitleMax__3UbvF{
    font-size: 11px;
    font-weight: 500;
    color: black;
}
.OpstinaWeather_tempTitleMin__3ni8u{
    font-size: 11px;
    font-weight: 500;
    color: silver;
}
.OpstinaWeather_tempValueMax__2k0vO{
    font-size: 18px;
    font-weight: 500;
    color: black;
    margin-left: 5px;
}
.OpstinaWeather_tempValueMin__1ZYh-{
    font-size: 18px;
    font-weight: 500;
    color: silver;
    margin-left: 8px;
}

.OpstinaWeather_predictability_div__1ewv2{ 
    text-align: center;
    margin-bottom:0px;
    justify-content: space-evenly;
    margin: 1px 20px;
}
.OpstinaWeather_predictability_tooltip__2xRZf{ 
    font-size: 10px;
    color: silver; 
    margin: 0px;
    margin-top: 2px;
}
.OpstinaWeather_precipitation_div__xrtqw { 
    display: flex;
    place-content: center;
    align-items: center;
    margin-top: 16px;
}
.OpstinaWeather_rain_icon__3c2jz { 
    width: 20px;
    height: 20px; 
    border-radius: 5px;
    text-align: center;
 }
.OpstinaWeather_precipitation_probability__2Ptud {
    font-size: 14px;
    margin: 0px;
    margin-left: 5px;
 }
.OpstinaWeather_wind_div__3RqwG { 
    margin-top: 16px;
    align-items: center;
    place-content: center;
    text-align: center;
}
.OpstinaWeather_wind_degrees__3wk01 { 
    font-size: 14px;
    margin: 0px;
}
.OpstinaWeather_wind_speed__2dhJg { 
    font-size: 12px;
    margin: 0px;
    margin-bottom: 3px;
    font-weight: 500;
}
.OpstinaWeather_humidity__1Q3vf { 
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0px;
    place-content: center;
    text-align: center;
}
.OpstinaWeather_tooltip__3JpAm { 
    font-size: 12px;
    color: silver;
    margin: 0px;
    margin-top: 2px;
    margin-bottom: 15px;
    text-align: center;
}
.OpstinaWeather_pressure__3llpy { 
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 0px;
    place-content: center;
    text-align: center;
}
.MinistarstvoLandingNew_container__2BR9L {
    background-color: #F6F6F7;
}

.MinistarstvoLandingNew_title__2onq6 {
    font-size: 16px;

    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: 400;
    color: darkgrey;
    text-align: center;
}

.MinistarstvoLandingNew_title2__3jH9x {
    font-size: 16px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: 400;
    color: darkgrey;
    text-align: center;
    background-color: white;
    border-radius: 8px;
}

.MinistarstvoLandingNew_featuredRow__3yzZv {
    /* width: 92%; */
    display: flex;
    justify-content: space-between;
    background-color: #F6F6F7;
    margin: 0 4% 0 4%;
}

.MinistarstvoLandingNew_featuredItem__1nSqU {
    flex: 1 1;
    margin: 5px 30px;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(154, 160, 185, 0.2), 0 15px 40px rgba(166, 173, 201, 0.1);
    background-color: white !important;

}

.MinistarstvoLandingNew_feturedTop__3w73u {
    width: 100%;
    display: flex;
    justify-content: space-between;

}

.MinistarstvoLandingNew_featuredTitle__2AzOc {
    font-size: 16px;
    font-weight: 400;
    color: grey;
}

.MinistarstvoLandingNew_featuredContainer__34KBD {
    margin: 5px 0px;
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid darkgrey;
}

.MinistarstvoLandingNew_featuredNumber__Thhmq {
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 5px;
}

.MinistarstvoLandingNew_featuredSub__EOI4f {
    font-size: 11px;
    color: grey;
}

.MinistarstvoLandingNew_featuredSub2__3nowp {
    font-size: 11px;
    color: black;
    font-weight: 500;
    margin-left: 3px;
}

.MinistarstvoLandingNew_featuredContainerBottom__3gTel {
    margin: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.MinistarstvoLandingNew_featuredItemBottom1__3z3id {
    margin: 20px 10px;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(154, 160, 185, 0.2), 0 15px 40px rgba(166, 173, 201, 0.1);
    background-color: white !important;
    height: 500px;
    overflow: scroll;
    width: 58%;

}

.MinistarstvoLandingNew_featuredItemBottom2__1jyB- {
    margin: 20px 10px;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(154, 160, 185, 0.2), 0 15px 40px rgba(166, 173, 201, 0.1);
    background-color: white !important;
    height: 500px;
    overflow: scroll;
    width: 41%;

}

.MinistarstvoLandingNew_tableCenter__1zq4b {
    text-align: -webkit-center;
    border-radius: 8px;
    background-color: white !important;
}

.MinistarstvoLandingNew_seedingRow__3y4oY {
    background-color: white;
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;
    align-items: center;

}

.MinistarstvoLandingNew_content_container__19Cjj {
    margin-top: 8px;
    align-items: center;
    width: 650px;
}

.MinistarstvoLandingNew_content_border__1DjKJ {
    background: #4FA2CD;
    border-radius: 20px;
    width: 100%;
    height: 2px;
}

.MinistarstvoLandingNew_table__1w63G {
    width: 100%;
    border-collapse: collapse;
    display: table;
}

.MinistarstvoLandingNew_table_header__DCYID {

    font-size: 14px;
    font-weight: 500;
    color: #4FA2CD;
    text-align: left;
    padding: 16px 0px 16px 8px;
    display: table-cell;
}

.MinistarstvoLandingNew_table_row__2nmGb {
    border-bottom: solid 1px rgba(0, 0, 0, .05);
    display: table-row;
}

.MinistarstvoLandingNew_table_border__-8CyR {
    background: #9c9c9c;
    border-radius: 20px;
    width: 100%;
    height: 2px;
}

.MinistarstvoLandingNew_table_row_text__126Bq {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.3px;
    color: #333;
    text-align: left;
    padding: 8px 0px 8px 8px;
    display: table-cell;
}

.MinistarstvoLandingNew_table_row_text_bold__1pgXp {
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: -0.3px;
    color: black;
    text-align: left;
    padding: 8px 0px 8px 8px;
    display: table-cell;
    font-weight: 500;
}

.MinistarstvoLandingNew_table_row_odd__5VB4b {
    border-bottom: solid 1px rgba(0, 0, 0, .07);
    background: #f8f8f8;
    display: table-row;
}

.MinistarstvoLandingNew_table_body__1SkQs {
    display: block;
    overflow: auto;
    height: 400px;
    width: 100%;
}

.MinistarstvoLandingNew_table_head__1eolP {
    display: table-header-group;
    vertical-align: middle;
    overflow: hidden;
}

.MinistarstvoLandingNew_table_row_header__3tE_T {
    border-bottom: solid 1px rgba(0, 0, 0, .07);
    display: block;
}

.MinistarstvoLandingNew_table_div__1Usgt {
    overflow-y: auto;
    max-height: 380px;
    transition: height 0.2s;
}

.MinistarstvoLandingNew_featuredRowBottom__3UnwS {
    padding-bottom: 30px;
    margin: 40px 5%;
    box-shadow: 0 5px 10px rgba(154, 160, 185, 0.2), 0 15px 40px rgba(166, 173, 201, 0.1);
    background-color: white;
    border-radius: 8px;
}
.popup-parcel .leaflet-popup-content-wrapper{
    background-color: #d6e2ea;
    opacity: 0.9;
}
.popup-parcel .leaflet-popup-tip {
    opacity: 0;
}

.popup-parcel-name {
    font-size: 24px;
    font-weight: bold;
    margin-top: 10px;
}

.popup-parcel-subtitle {
    font-size: 18px;
    margin-top: 10px;
}

.popup-parcel-attributes {
    font-size: 14px;
    font-style: italic;
}


.popup-sensor .leaflet-popup-content-wrapper{
    background-color: #ffffff;
    opacity: 0.9;
}

.popup-sensor .leaflet-popup-content{
    margin-top: 0px;
    margin-bottom: 0px;
}

.popup-sensor .leaflet-popup-tip {
    opacity: 0;
}

.popup-sensor-name {
    font-size: 24px;
    font-weight: bold;
    margin-top: 10px;
}

.popup-sensor-subtitle {
    font-size: 18px;
    margin-top: 10px;
}

.popup-sensor-attributes {
    font-size: 14px;
    font-style: italic;
}

img.leaflet-marker-icon { 
    filter: drop-shadow(1px 1px 1px #474747);
    /* opacity: 0.9; */
}
.institutional-layer-label-tooltip.leaflet-tooltip {
    background-color: rgba(255, 255, 255, 0);
    opacity: 0;
    border: hidden;
    box-shadow: none;
}

.institutional-layer-label-tooltip-text {
    font-size: 16px;
    font-weight: bold;
}

.datePicker {
    display: flex;
    margin: 0 auto;
    padding: 6px 8px;
    justify-content: left;
    position: relative;
    left: 40px;
}
.parcel-attributes-panel {
    background: #fff;
    position: absolute;
    bottom: 100px;
    left: 350px;
    width: 300px;
    z-index: 1002;
    max-width: 500px;
    padding: 20px 12px 10px 20px;
    font: 13px Arial, Helvetica, sans-serif;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.form-style-2-heading{
    font-weight: bold;
    font-style: italic;
    border-bottom: 2px solid #ddd;
    margin-bottom: 20px;
    font-size: 15px;
    padding-bottom: 3px;
}
.parcel-attributes-panel label{
    display: block;
    margin: 0px 0px 15px 0px;
}
.parcel-attributes-panel label > span{
    width: 100px;
    font-weight: bold;
    float: left;
    padding-top: 8px;
    padding-right: 5px;
}
.parcel-attributes-panel span.required{
    color:red;
}
.parcel-attributes-panel .tel-number-field{
    width: 40px;
    text-align: center;
}
.parcel-attributes-panel input.input-field, .parcel-attributes-panel .select-field{
    width: 48%;
}
.form-style-2 input.input-field,
.form-style-2 .tel-number-field,
.form-style-2 .textarea-field,
.form-style-2 .select-field{
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    border: 1px solid #C2C2C2;
    box-shadow: 1px 1px 4px #EBEBEB;
    -moz-box-shadow: 1px 1px 4px #EBEBEB;
    -webkit-box-shadow: 1px 1px 4px #EBEBEB;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    padding: 7px;
    outline: none;
}
.form-style-2 .input-field:focus,
.form-style-2 .tel-number-field:focus,
.form-style-2 .textarea-field:focus,
.form-style-2 .select-field:focus{
    border: 1px solid #0C0;
}
.form-style-2 .textarea-field{
    height:100px;
    width: 55%;
}
.parcel-attributes-panel input[type=submit],
.parcel-attributes-panel input[type=button]{
    display: block;
    border: none;
    padding: 8px 15px 8px 15px;
    color: #000;
    box-shadow: 1px 1px 4px #DADADA;
    -moz-box-shadow: 1px 1px 4px #DADADA;
    -webkit-box-shadow: 1px 1px 4px #DADADA;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
}
.zoom-to-state-panel {
    background: #fff;
    position: absolute;
    top: 61px;
    right: 5px;
    width: 42px;
    height: 42px;
    z-index: 10002;
    max-width: 300px;
    font: 13px Arial, Helvetica, sans-serif;
    border-radius: 5px;
    border: 1px solid rgba(0,0,0,1);
    /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
}

.zoom-to-state-panel label{
    display: block;
    margin: 0px 0px 15px 0px;
}
.zoom-to-state-panel label > span{
    width: 100px;
    font-weight: bold;
    float: left;
    padding-top: 8px;
    padding-right: 5px;
}

.zoom-to-state-panel .tel-number-field{
    width: 40px;
    text-align: center;
}
.zoom-to-state-panel input.input-field, .zoom-to-state-panel .select-field{
    width: 48%;
}

.leaflet-container {
    height:100%;
    margin: 0 auto;
}

.leaflet-control-layers {
    visibility: hidden !important;
}

.leaflet-tile {
    text-align:left !important;
}

.map-comtainer {
    text-align:left !important;
    position: absolute;
    transition: all .3s ease-in-out;
    transition-property: top,left,bottom,right,width;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.sr-only {
    display: none;
}
.meteotable_page__6F64p {
    width: 100%;
}

.meteotable_card__2y3yU {
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(154, 160, 185, 0.2), 0 15px 40px rgba(166, 173, 201, 0.1);
    background-color: #fff;
    width: auto;
    margin: 30px 40px 40px 40px;
}

.meteotable_back_container__27DPs {
    display: flex;
    padding: 30px 0px 0px 0px;
    cursor: pointer;
    margin-left: 40px;
    -webkit-user-select: none;
            user-select: none;
    width: -moz-fit-content;
    width: fit-content;
}

.meteotable_back__3vsKc {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.3px;
    color: #333;
    line-height: 24px;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
}

.meteotable_title__R2YEx {
    font-family: Roboto;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: -0.3px;
    color: #333;
    line-height: 24px;
    -webkit-user-select: none;
            user-select: none;
    text-align: center;
    margin-bottom: 0px;
    background: #058457;
    color: #fff;
    padding: 24px 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.meteotable_sections_container__19AGY {
    padding: 40px;
}

.meteotable_section__iEX7G {
    margin: 0 20px 40px 0;
}

.meteotable_title_container__2R3RZ {
    display: flex;
    justify-content: space-between;
}

.meteotable_title_left__3TkYD {
    display: flex;
    margin: 0 8px;
}

.meteotable_title_icon__21Z-5 {
    -webkit-user-select: none;
            user-select: none;
}

.meteotable_section_title__2UWc1 {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.3px;
    color: #333;
    line-height: 26px;
    margin: 0px 15px;
}

.meteotable_add_button__1qYnl {
    height: 26px;
    width: 26px;
    background-color: #58B4E5;
    border-radius: 50%;
    box-shadow: 2px 2px 5px 0px rgb(0 0 0 / 20%);
    -webkit-user-select: none;
            user-select: none;
    cursor: pointer;
}

.meteotable_add_button__1qYnl:hover {
    background-color: #4FA2CD;
    transition: background-color 0.3s;
}

.meteotable_add_icon__RbWlS {
    margin: 4px;
    filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(180deg) brightness(100%) contrast(100%);
    -webkit-user-select: none;
            user-select: none;
}

.meteotable_add_icon_menu__2CHZ0 {
    margin: 4px;
    filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(180deg) brightness(100%) contrast(100%);
    -webkit-user-select: none;
            user-select: none;
    pointer-events: none;
    margin-top: 16px;
    padding-left: 2px;
}

.meteotable_edit_icon__2A4td {
    -webkit-user-select: none;
            user-select: none;
    cursor: pointer;
    margin: 0 8px;
}

.meteotable_content_container__2t-vM {
    margin-top: 8px;
}

.meteotable_content_border__2inKn {
    background: #126038;
    border-radius: 20px;
    width: 100%;
    height: 2px;
}

.meteotable_content_empty__3NCSC {
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: -0.27px;
    color: rgb(83, 83, 83);
    margin: 0 8px;
}

.meteotable_operation_title__3x4cO {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.3px;
    color: #333;
    line-height: 26px;
    margin: 0px 8px 30px 8px;
}

.meteotable_working_operation_color__1p1sO {
    margin: 14px 8px 0px 8px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.3px;
    color: #58B4E5;
}

.meteotable_working_operation__2Jh6G {
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: -0.27px;
    color: rgb(39, 39, 39);
    margin: 2px 8px 0px 8px;
}

.meteotable_content_flex__9AnQM {
    display: flex;
}

.meteotable_content_between__V3Sqv {
    display: flex;
    justify-content: space-between;
}

.meteotable_table__1Jh1p {
    width: 100%;
    border-collapse: collapse;
    display: table;
}

.meteotable_table_header__CzSYy {
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #58B4E5;
    text-align: left;
    padding: 8px 0px 8px 8px;
    display: table-cell;
    text-align: center;
    font-weight: 500;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
}

.meteotable_table_row__2zdX5 {
    border-bottom: solid 1px rgba(0, 0, 0, .07);
    display: table-row;
}

.meteotable_table_row_sticky_header__35yN3 {
    border-bottom: solid 1px rgba(0, 0, 0, .07);
    display: table-row;
    position: sticky;
    inset-block-start: 0;
    background: white;
}

.meteotable_table_border___U4L6 {
    background: #9c9c9c;
    border-radius: 20px;
    width: 100%;
    height: 2px;
}

.meteotable_table_header_end__17FQC {
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #58B4E5;
    text-align: end;
    width: 50%;
    padding: 8px 8px 8px 0px;
    display: table-cell;
}

.meteotable_table_row_text__3eGnG {
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #333;
    text-align: left;
    padding: 8px 0px 8px 8px;
    display: table-cell;
    text-align: center;
}

.meteotable_table_row_end__1t_E- {
    width: 50%;
    text-align: end;
    padding-right: 8px;
    display: table-cell;
}

.meteotable_table_row_odd__2GskO {
    border-bottom: solid 1px rgba(0, 0, 0, .05);
    background: #f8f8f8;
    display: table-row;
}

.meteotable_table_body__1LldR {
    display: block;
    overflow: auto;
    height: 300px;
    width: 100%;
}

.meteotable_table_head__-ZhDz {
    display: table-header-group;
    vertical-align: middle;
    overflow: hidden;
}

.meteotable_table_footer__uiX4t {
    position: sticky;
    inset-block-end: 0;
    background: #fff;
    font-weight: 500;
}

.meteotable_table_row_header__3LYTW {
    border-bottom: solid 1px rgba(0, 0, 0, .05);
    display: block;
}

.meteotable_table_div__3W8FS {
    overflow-y: auto;
    max-height: 258px;
    transition: height 0.2s;
    margin: 10px 7% 0 7%;
    border-bottom: solid 1px rgba(0, 0, 0, .05);
    border-top: solid 1px rgba(0, 0, 0, .05);
    border-left: solid 1px rgba(0, 0, 0, .05);
}

.meteotable_table_div__3W8FS::-webkit-scrollbar-track {
    background: #f8f8f8;
    border-radius: 0px;
    border: solid 1px rgba(0, 0, 0, .05);
}

.meteotable_delete_button__3xMbv {
    margin-top: 14px;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
    margin-right: 5px;
}

.meteotable_delete_button_table__kSj-J {
    margin-top: 4px;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
    margin-right: 4px;
}

.meteotable_table_row_text_bold__36Vq7 {
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #333;
    text-align: left;
    padding: 8px 0px 8px 8px;
    display: table-cell;
    font-weight: 500;
}

.meteotable_table_row_text_bold_right__lY3rk {
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #333;
    text-align: right;
    padding: 8px 0px 8px 8px;
    display: table-cell;
    font-weight: 500;
}


.meteotable_save_button__22bJQ {
    height: 40px;
    font-size: 20px;
    color: white;
    width: 120px;
    background-color: #58B4E5;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 0px rgb(0 0 0 / 20%);
    -webkit-user-select: none;
            user-select: none;
    cursor: pointer;
    line-height: 40px;
    text-align: center;
    margin: 36px 10px 6px 10px;
}

.meteotable_save_button__22bJQ:hover {
    background-color: #4FA2CD;
    transition: background-color 0.3s;
}

.meteotable_save_button_disabled__MFr-D {
    height: 40px;
    font-size: 20px;
    color: white;
    width: 120px;
    background-color: #a5a5a5;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 0px rgb(0 0 0 / 20%);
    -webkit-user-select: none;
            user-select: none;
    line-height: 40px;
    text-align: center;
    margin: 36px 10px 6px 10px;
}
.meteoinfo_content__m6stY {
    padding-top: 10px;
    justify-content: space-between;
}

.meteoinfo_center__1Teax {
    text-align: center;
    padding-left: 30px;
}
@font-face {
  font-family: 'icomoon';
  src:  url(../../static/media/icomoon.a2df7224.eot);
  src:  url(../../static/media/icomoon.a2df7224.eot#iefix) format('embedded-opentype'),
    url(../../static/media/icomoon.4ea4066c.ttf) format('truetype'),
    url(../../static/media/icomoon.3eea3f66.woff) format('woff'),
    url(../../static/media/icomoon.3ebf94d0.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-csv-file:before {
  content: "\EA83";
}
.icon-pdf-file:before {
  content: "\EA84";
}
.icon-txt-file:before {
  content: "\EA85";
}
.icon-Season:before {
  content: "\EA7F";
}
.icon-PodTabla:before {
  content: "\EA5F";
}
.icon-TableLista:before {
  content: "\EA5E";
}
.icon-cropMarker-2:before {
  content: "\EA3B";
}
.icon-cropMarker-1 .path1:before {
  content: "\EA3D";
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path2:before {
  content: "\EA3E";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path3:before {
  content: "\EA3F";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path4:before {
  content: "\EA42";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path5:before {
  content: "\EA43";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path6:before {
  content: "\EA45";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path7:before {
  content: "\EA46";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path8:before {
  content: "\EA47";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path9:before {
  content: "\EA48";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path10:before {
  content: "\EA49";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path11:before {
  content: "\EA4A";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path12:before {
  content: "\EA4B";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path13:before {
  content: "\EA4C";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path14:before {
  content: "\EA4D";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path15:before {
  content: "\EA4E";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path16:before {
  content: "\EA4F";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path17:before {
  content: "\EA50";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path18:before {
  content: "\EA51";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path19:before {
  content: "\EA52";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path20:before {
  content: "\EA53";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path21:before {
  content: "\EA54";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path22:before {
  content: "\EA56";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path23:before {
  content: "\EA57";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path24:before {
  content: "\EA58";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path25:before {
  content: "\EA59";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path26:before {
  content: "\EA5A";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path27:before {
  content: "\EA5B";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path28:before {
  content: "\EA5C";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path29:before {
  content: "\EA5D";
  margin-left: -1em;
  color: rgb(239, 65, 54);
}
.icon-Pecurke:before {
  content: "\EA38";
}
.icon-Hortukulturne:before {
  content: "\EA39";
}
.icon-Predator-01:before {
  content: "\EA35";
}
.icon-Mamac-01:before {
  content: "\EA36";
}
.icon-Feremonske-Klopke-01:before {
  content: "\EA37";
}
.icon-Straight:before {
  content: "\EA25";
}
.icon-Complex:before {
  content: "\EA26";
}
.icon-Micronutrients:before {
  content: "\EA27";
}
.icon-Farmyard-manure:before {
  content: "\EA28";
}
.icon-Green-manure:before {
  content: "\EA29";
}
.icon-Compost:before {
  content: "\EA2A";
}
.icon-Vermicompost:before {
  content: "\EA2B";
}
.icon-Insecticides:before {
  content: "\EA2C";
}
.icon-Fungicides:before {
  content: "\EA2D";
}
.icon-Herbicides:before {
  content: "\EA2E";
}
.icon-Repellents:before {
  content: "\EA2F";
}
.icon-Rodenticides:before {
  content: "\EA30";
}
.icon-Rest:before {
  content: "\EA31";
}
.icon-Disel:before {
  content: "\EA32";
}
.icon-DrvenaAmbalaza:before {
  content: "\EA33";
}
.icon-Cocoa2:before {
  content: "\EA34";
}
.icon-DopunskaDjubrivaStartnaIDjubrivaZaPrihranu:before {
  content: "\E9BF";
}
.icon-DjubrivaZaTretiranjeSetvenogSadnogMaterijala:before {
  content: "\E9A7";
}
.icon-RatarskaKultura:before {
  content: "\E9A8";
}
.icon-PovrtarskeKulture:before {
  content: "\E9A9";
}
.icon-StaklenaAmbalaza:before {
  content: "\E9AA";
}
.icon-AromatineZacinskeILekoviteKulture:before {
  content: "\E9AB";
}
.icon-ProizvodiPovrtarskihKultura:before {
  content: "\E9AE";
}
.icon-ProizvodiRatarskihKultura:before {
  content: "\E9AF";
}
.icon-ProizvodiAromatinihZainskihILekovitihKultura:before {
  content: "\E9A3";
}
.icon-ProizvodiVocaIVinoveLoze:before {
  content: "\E9A4";
}
.icon-RegulatoriRasta:before {
  content: "\E9A5";
}
.icon-PreparatiSpravljeniNaGazdinstvu:before {
  content: "\E9A6";
}
.icon-Supstrati:before {
  content: "\E9A2";
}
.icon-SredstvaZaTretiranjeSemena:before {
  content: "\E9B1";
}
.icon-SadniceVocaIVinoveLoze:before {
  content: "\E9BE";
}
.icon-Rasad:before {
  content: "\E9B2";
}
.icon-PomocnaSredstva:before {
  content: "\E9B3";
}
.icon-PlastinaAmbalaza:before {
  content: "\E9B4";
}
.icon-PapirnaAmbalaza:before {
  content: "\E9B5";
}
.icon-OsnovnaDjubriva:before {
  content: "\E9B6";
}
.icon-Insekticidi:before {
  content: "\E9B7";
}
.icon-HortikulturneKulture:before {
  content: "\E9B8";
}
.icon-Herbicidi:before {
  content: "\E9B9";
}
.icon-Gorivo:before {
  content: "\E9BC";
}
.icon-Fungicidi:before {
  content: "\E9BD";
}
.icon-Cocoa .path1:before {
  content: "\E9A1";
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path2:before {
  content: "\E9C0";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path3:before {
  content: "\E9C1";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path4:before {
  content: "\E9C2";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path5:before {
  content: "\E9C3";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path6:before {
  content: "\E9C4";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path7:before {
  content: "\E9C9";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path8:before {
  content: "\E9CA";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path9:before {
  content: "\E9CC";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path10:before {
  content: "\E9CD";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path11:before {
  content: "\E9CE";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path12:before {
  content: "\E9CF";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path13:before {
  content: "\E9D0";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path14:before {
  content: "\E9D1";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path15:before {
  content: "\E9D2";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path16:before {
  content: "\E9D3";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path17:before {
  content: "\E9D4";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path18:before {
  content: "\E9D5";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path19:before {
  content: "\E9D6";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path20:before {
  content: "\E9DA";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path21:before {
  content: "\E9DB";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path22:before {
  content: "\E9DC";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path23:before {
  content: "\E9DD";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path24:before {
  content: "\E9DE";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path25:before {
  content: "\E9F8";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path26:before {
  content: "\E9F9";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path27:before {
  content: "\E9FA";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path28:before {
  content: "\EA05";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path29:before {
  content: "\EA06";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path30:before {
  content: "\EA0A";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path31:before {
  content: "\EA0B";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path32:before {
  content: "\EA0F";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path33:before {
  content: "\EA10";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path34:before {
  content: "\EA11";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path35:before {
  content: "\EA12";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path36:before {
  content: "\EA13";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path37:before {
  content: "\EA14";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path38:before {
  content: "\EA18";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path39:before {
  content: "\EA19";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path40:before {
  content: "\EA1A";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path41:before {
  content: "\EA1B";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path42:before {
  content: "\EA1C";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path43:before {
  content: "\EA1D";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path44:before {
  content: "\EA1E";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path45:before {
  content: "\EA1F";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path46:before {
  content: "\EA20";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path47:before {
  content: "\EA21";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path48:before {
  content: "\EA22";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path49:before {
  content: "\EA23";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path50:before {
  content: "\EA24";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path51:before {
  content: "\EA60";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path52:before {
  content: "\EA61";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path53:before {
  content: "\EA62";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path54:before {
  content: "\EA63";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path55:before {
  content: "\EA64";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path56:before {
  content: "\EA65";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path57:before {
  content: "\EA66";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path58:before {
  content: "\EA67";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path59:before {
  content: "\EA68";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path60:before {
  content: "\EA69";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path61:before {
  content: "\EA6A";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path62:before {
  content: "\EA6B";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path63:before {
  content: "\EA6C";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path64:before {
  content: "\EA6D";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path65:before {
  content: "\EA6E";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path66:before {
  content: "\EA6F";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path67:before {
  content: "\EA70";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path68:before {
  content: "\EA71";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path69:before {
  content: "\EA72";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path70:before {
  content: "\EA73";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path71:before {
  content: "\EA74";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path72:before {
  content: "\EA75";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path73:before {
  content: "\EA76";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path74:before {
  content: "\EA77";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path75:before {
  content: "\EA78";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path76:before {
  content: "\EA79";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path77:before {
  content: "\EA7A";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path78:before {
  content: "\EA7B";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path79:before {
  content: "\EA7C";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path80:before {
  content: "\EA7D";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path81:before {
  content: "\EA7E";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Solanum:before {
  content: "\E9A0";
}
.icon-uniE905:before {
  content: "\E905";
}
.icon-AgroLife:before {
  content: "\E906";
}
.icon-home:before {
  content: "\E907";
}
.icon-Beleske:before {
  content: "\E908";
}
.icon-newspaper:before {
  content: "\E909";
}
.icon-pencil:before {
  content: "\E90A";
}
.icon-Biljka:before {
  content: "\E90B";
}
.icon-Buba:before {
  content: "\E90C";
}
.icon-CaseLil:before {
  content: "\E90D";
}
.icon-Claas:before {
  content: "\E90E";
}
.icon-CvekPrska:before {
  content: "\E90F";
}
.icon-Detelina:before {
  content: "\E910";
}
.icon-image:before {
  content: "\E911";
}
.icon-images:before {
  content: "\E98C";
}
.icon-camera:before {
  content: "\E98D";
}
.icon-Detuz:before {
  content: "\E98E";
}
.icon-Djubrivo:before {
  content: "\E915";
}
.icon-Dokument:before {
  content: "\E916";
}
.icon-Dokument1:before {
  content: "\E918";
}
.icon-Drvo:before {
  content: "\E91A";
}
.icon-Fabrika:before {
  content: "\E91B";
}
.icon-Fendt:before {
  content: "\E91D";
}
.icon-Fotoaparat:before {
  content: "\E91F";
}
.icon-Grabulje:before {
  content: "\E921";
}
.icon-Grasak:before {
  content: "\E923";
}
.icon-Iks:before {
  content: "\E926";
}
.icon-Informacija:before {
  content: "\E928";
}
.icon-Izmeni:before {
  content: "\E929";
}
.icon-Izmeni1:before {
  content: "\E92B";
}
.icon-JohnDeere:before {
  content: "\E98B";
}
.icon-Kafa:before {
  content: "\E98A";
}
.icon-Kalendar:before {
  content: "\E989";
}
.icon-Kanistar:before {
  content: "\E92D";
}
.icon-Kanta:before {
  content: "\E930";
}
.icon-KanticaZaUlje:before {
  content: "\E931";
}
.icon-file-empty:before {
  content: "\E934";
}
.icon-files-empty:before {
  content: "\E936";
}
.icon-file-text:before {
  content: "\E938";
}
.icon-Kap:before {
  content: "\E939";
}
.icon-Karfiol:before {
  content: "\E93B";
}
.icon-Kljuc:before {
  content: "\E93D";
}
.icon-Kofa:before {
  content: "\E940";
}
.icon-Kombajn:before {
  content: "\E941";
}
.icon-Krava:before {
  content: "\E944";
}
.icon-Kupi:before {
  content: "\E945";
}
.icon-stack:before {
  content: "\E983";
}
.icon-Kutija:before {
  content: "\E984";
}
.icon-Laverda:before {
  content: "\E985";
}
.icon-List:before {
  content: "\E946";
}
.icon-Lista:before {
  content: "\E947";
}
.icon-ListURuci:before {
  content: "\E948";
}
.icon-Lokacija:before {
  content: "\E949";
}
.icon-price-tag:before {
  content: "\E94A";
}
.icon-price-tags:before {
  content: "\E94B";
}
.icon-barcode:before {
  content: "\E94C";
}
.icon-Lopata:before {
  content: "\E94D";
}
.icon-LopataZaOranje:before {
  content: "\E94E";
}
.icon-Lopov:before {
  content: "\E950";
}
.icon-MarketTorba:before {
  content: "\E951";
}
.icon-MassevFerguson:before {
  content: "\E952";
}
.icon-MedicinskiKofer:before {
  content: "\E953";
}
.icon-Merac:before {
  content: "\E982";
}
.icon-credit-card:before {
  content: "\E981";
}
.icon-NavigacioniTab:before {
  content: "\E980";
}
.icon-NewHolland:before {
  content: "\E954";
}
.icon-phone:before {
  content: "\E955";
}
.icon-NoviRadniNalog:before {
  content: "\E956";
}
.icon-Pamuk:before {
  content: "\E957";
}
.icon-Plus:before {
  content: "\E958";
}
.icon-Pomoc:before {
  content: "\E959";
}
.icon-Povrce:before {
  content: "\E95A";
}
.icon-Prikaciti:before {
  content: "\E95B";
}
.icon-PrikljucnaMasina:before {
  content: "\E95C";
}
.icon-Prodaj:before {
  content: "\E95D";
}
.icon-map:before {
  content: "\E94F";
}
.icon-Profil:before {
  content: "\E95E";
}
.icon-Prskalica:before {
  content: "\E95F";
}
.icon-Pumpica:before {
  content: "\E97D";
}
.icon-Redo:before {
  content: "\E97E";
}
.icon-Repa:before {
  content: "\E97F";
}
.icon-Rostselmash:before {
  content: "\E960";
}
.icon-Sampo:before {
  content: "\E961";
}
.icon-Sargarepa:before {
  content: "\E962";
}
.icon-Stiklic:before {
  content: "\E963";
}
.icon-StrelicaDole1:before {
  content: "\E964";
}
.icon-Table:before {
  content: "\E965";
}
.icon-Telefon:before {
  content: "\E966";
}
.icon-mobile:before {
  content: "\E967";
}
.icon-Traktor:before {
  content: "\E968";
}
.icon-Travka:before {
  content: "\E969";
}
.icon-TrenutnaLokacija:before {
  content: "\E96A";
}
.icon-Undo:before {
  content: "\E96B";
}
.icon-Uzvicnik:before {
  content: "\E96C";
}
.icon-box-add:before {
  content: "\E97C";
}
.icon-box-remove:before {
  content: "\E97B";
}
.icon-Vatra:before {
  content: "\E97A";
}
.icon-Vise:before {
  content: "\E96D";
}
.icon-VoceIGrozdje:before {
  content: "\E96E";
}
.icon-Web:before {
  content: "\E96F";
}
.icon-ZalivanjeIzKantice:before {
  content: "\E970";
}
.icon-Zito:before {
  content: "\E971";
}
.icon-Zubcanik:before {
  content: "\E972";
}
.icon-bubbles:before {
  content: "\E973";
}
.icon-bubbles2:before {
  content: "\E974";
}
.icon-user:before {
  content: "\E975";
}
.icon-users:before {
  content: "\E976";
}
.icon-user-plus:before {
  content: "\E977";
}
.icon-user-minus:before {
  content: "\E978";
}
.icon-user-check:before {
  content: "\E979";
}
.icon-search1:before {
  content: "\E986";
}
.icon-zoom-in:before {
  content: "\E987";
}
.icon-zoom-out:before {
  content: "\E988";
}
.icon-lock:before {
  content: "\E98F";
}
.icon-unlocked:before {
  content: "\E990";
}
.icon-equalizer:before {
  content: "\E992";
}
.icon-cog:before {
  content: "\E994";
}
.icon-aid-kit:before {
  content: "\E998";
}
.icon-bug:before {
  content: "\E999";
}
.icon-bin:before {
  content: "\E9AC";
}
.icon-bin2:before {
  content: "\E9AD";
}
.icon-truck:before {
  content: "\E9B0";
}
.icon-list:before {
  content: "\E9BA";
}
.icon-list2:before {
  content: "\E9BB";
}
.icon-download:before {
  content: "\E9C5";
}
.icon-upload:before {
  content: "\E9C6";
}
.icon-download2:before {
  content: "\E9C7";
}
.icon-upload2:before {
  content: "\E9C8";
}
.icon-link:before {
  content: "\E9CB";
}
.icon-star-empty:before {
  content: "\E9D7";
}
.icon-star-half:before {
  content: "\E9D8";
}
.icon-star-full:before {
  content: "\E9D9";
}
.icon-warning:before {
  content: "\EA07";
}
.icon-notification:before {
  content: "\EA08";
}
.icon-question:before {
  content: "\EA09";
}
.icon-info:before {
  content: "\EA0C";
}
.icon-cancel-circle:before {
  content: "\EA0D";
}
.icon-blocked:before {
  content: "\EA0E";
}
.icon-play:before {
  content: "\EA15";
}
.icon-pause:before {
  content: "\EA16";
}
.icon-stop:before {
  content: "\EA17";
}
.icon-arrow-right:before {
  content: "\EA3C";
}
.icon-arrow-left:before {
  content: "\EA40";
}
.icon-circle-up:before {
  content: "\EA41";
}
.icon-circle-left:before {
  content: "\EA44";
}
.icon-google:before {
  content: "\EA88";
}
.icon-facebook:before {
  content: "\EA90";
}
.icon-tux:before {
  content: "\EABD";
}
.icon-apple:before {
  content: "\EABE";
}
.icon-finder:before {
  content: "\EABF";
}
.icon-android:before {
  content: "\EAC0";
}
.icon-windows:before {
  content: "\EAC1";
}
.icon-windows8:before {
  content: "\EAC2";
}
.icon-file-pdf:before {
  content: "\EADF";
}
.icon-file-openoffice:before {
  content: "\EAE0";
}
.icon-file-word:before {
  content: "\EAE1";
}
.icon-Amaranthus:before {
  content: "\E99A";
}
.icon-Beverage-crops-product:before {
  content: "\E997";
}
.icon-Cabbage:before {
  content: "\E996";
}
.icon-Cashew:before {
  content: "\E991";
}
.icon-Cassava:before {
  content: "\E993";
}
.icon-Cereal-or-grain-crops-product:before {
  content: "\E995";
}
.icon-Coconut:before {
  content: "\E9DF";
}
.icon-Coffee:before {
  content: "\E9E0";
}
.icon-Corchorus:before {
  content: "\E9E1";
}
.icon-Cotton:before {
  content: "\E9E2";
}
.icon-Cowpea:before {
  content: "\E9E3";
}
.icon-Date-palm:before {
  content: "\E9E4";
}
.icon-Forage-legume:before {
  content: "\E9E5";
}
.icon-Groundnut:before {
  content: "\E9E6";
}
.icon-Irish-potato:before {
  content: "\E9E7";
}
.icon-Kenaf:before {
  content: "\E9E8";
}
.icon-Kola:before {
  content: "\E9E9";
}
.icon-Maize:before {
  content: "\E9EA";
}
.icon-Melon:before {
  content: "\E9EB";
}
.icon-Oil-crops-product:before {
  content: "\E9EC";
}
.icon-Oil-palm:before {
  content: "\E9ED";
}
.icon-Okra:before {
  content: "\E9EE";
}
.icon-Pearl-millet:before {
  content: "\E9EF";
}
.icon-Pepper:before {
  content: "\E9F0";
}
.icon-Potato:before {
  content: "\E9F1";
}
.icon-Raphia-palm:before {
  content: "\E9F2";
}
.icon-Rice:before {
  content: "\E9F3";
}
.icon-Root-and-tuber-crops-product:before {
  content: "\E9F4";
}
.icon-Rubber:before {
  content: "\E9F5";
}
.icon-Sesame:before {
  content: "\E9F6";
}
.icon-Sokoyokoto:before {
  content: "\E9F7";
}
.icon-Sorghum:before {
  content: "\E9FB";
}
.icon-Soybean:before {
  content: "\E9FC";
}
.icon-Sugarcane:before {
  content: "\E9FD";
}
.icon-Sunflower:before {
  content: "\E9FE";
}
.icon-Sweet-orange:before {
  content: "\E9FF";
}
.icon-Sweet-potato:before {
  content: "\EA00";
}
.icon-Tangelo:before {
  content: "\EA01";
}
.icon-Tomato:before {
  content: "\EA02";
}
.icon-Wheat:before {
  content: "\EA03";
}
.icon-Yam:before {
  content: "\EA04";
}
.icon-Beverage-crops-product2:before {
  content: "\E99E";
}
.icon-Cereal-or-grain-crops-product2:before {
  content: "\E99F";
}
.icon-Legumes:before {
  content: "\E99D";
}
.icon-Oil-crops-product2:before {
  content: "\E99C";
}
.icon-Root-and-tuber-crops-product2:before {
  content: "\E99B";
}
.icon-Working-Operation:before {
  content: "\E900";
}
.icon-Twiter:before {
  content: "\E901";
}
.icon-Tri-Tacke:before {
  content: "\E902";
}
.icon-Tiketing:before {
  content: "\E903";
}
.icon-Settings:before {
  content: "\E904";
}
.icon-Search:before {
  content: "\E912";
}
.icon-Resources:before {
  content: "\E913";
}
.icon-Material:before {
  content: "\E914";
}
.icon-Map:before {
  content: "\E917";
}
.icon-Machines:before {
  content: "\E919";
}
.icon-LogOut:before {
  content: "\E91C";
}
.icon-LogIn:before {
  content: "\E91E";
}
.icon-Location:before {
  content: "\E920";
}
.icon-linkedin:before {
  content: "\E922";
}
.icon-Instagram:before {
  content: "\E924";
}
.icon-Implements:before {
  content: "\E925";
}
.icon-Hamburger:before {
  content: "\E927";
}
.icon-Government:before {
  content: "\E92A";
}
.icon-G:before {
  content: "\E92C";
}
.icon-Fields:before {
  content: "\E92E";
}
.icon-Farmers:before {
  content: "\E92F";
}
.icon-Facebook:before {
  content: "\E932";
}
.icon-Delete:before {
  content: "\E933";
}
.icon-Crop:before {
  content: "\E935";
}
.icon-Common:before {
  content: "\E937";
}
.icon-ArrowLevo:before {
  content: "\E93A";
}
.icon-ArrowGore:before {
  content: "\E93C";
}
.icon-ArrowDole:before {
  content: "\E93E";
}
.icon-ArrowDesno:before {
  content: "\E93F";
}
.icon-AddFarmer:before {
  content: "\E942";
}
.icon-Add:before {
  content: "\E943";
}
.icon-file-excel:before {
  content: "\EAE2";
}
.icon-radio-checked2:before {
  content: "\EA55";
}
.icon-feed:before {
  content: "\EA3A";
}
.icon-pie-chart:before {
  content: "\EA80";
}
.icon-table2:before {
  content: "\EA81";
}
.icon-podcast:before {
  content: "\EA82";
}
.icon-search:before {
  content: "\F002";
}
.icon-filter:before {
  content: "\F0B0";
}
.icon-cut:before {
  content: "\F0C4";
}
.icon-scissors:before {
  content: "\F0C4";
}
.icon-libreoffice:before {
  content: "\EAE3";
}

html {
    font-family: 'Roboto', sans-serif;
}

body {
    font-size: 13px;
    line-height: 20px;
    margin: 0;
    background: #F6F6F7;
}

[class^="icon-"],
[class*=" icon-"] {
    font-size: 30px;
}

.loginIcon {
    margin: 0px 4px;
}

.logOutIcon {
    font-size: 32px;
}

.menuMoreButton {
    height: 45px !important;
    min-height: 35px !important;
    font-size: 30px !important;
    top: 25% !important;
    background: blue;
    background-color: #282bee;
    /* position: absolute !important; */
}

.moreIcon {
    font-size: 32px !important;
    color: #ffffff;
}

.layerIcon {
    font-size: 16px !important;
}

.tabs {
    background-color: #04764E !important;
}

.tabContainer {
    padding: 1px 20px;
    border-bottom: 3px !important;
    border-color: #4FA2CD !important;
    border-bottom-style: solid !important;
    background-color: #f7f7f7;
}

.dashboard-cards {
    width: 47.5% !important;
    margin: 10px !important;
    position: relative !important;
    float: right !important;
    border-color: #DCDCDC !important;
    border-style: solid !important;
    border-width: 1.5px !important;
    padding-bottom: 10px !important;
}

.dashboard-cards-full {
    width: 96% !important;
    margin: 10px !important;
    position: relative !important;
    float: right !important;
    border-color: #DCDCDC !important;
    border-style: solid !important;
    border-width: 1.5px !important;
    padding-bottom: 10px !important;
}

.dashboard-cards-agronom {
    width: 90% !important;
    margin: 10px !important;
    border-color: #DCDCDC !important;
    border-style: solid !important;
    border-width: 1.5px !important;
    padding-bottom: 10px !important;
}

.dashboard-title {
    font-size: 20px !important;
    padding: 10px !important;
    width: 100% !important;
    height: 30px !important;
    background-color: #DCDCDC !important;
}

.tableCss {
    border-color: white !important;
}

.tableRowCss {
    background-color: #04764E !important;
    color: #FAFAFA !important;
    border-color: white !important;
}

.menuIcon {
    font-size: 24px;
    color: #ffffff;
    margin-right: 10px;
    padding-right: 20px;
    width: 20px;
}

.dashboardIcon {
    font-size: 25px;
    color: #ffffff;
}

.closeIcon {
    font-size: 27px;
    color: #ffffff;
}

.dashboardIcon-selected {
    font-size: 25px;
    color: #61a50e;
}

.filter-panel-dashboard {
    float: right;
    margin-right: 30px;
}

.filter-label {
    margin: 10px;
    font-weight: bold;
}

.multi-select-in-filter-dashboards {
    width: 400px;
    max-width: 400px !important;
    margin: 10px 5px;
}

.react-select-dropdown {
    width: 256px;
    max-width: 256px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.react-select-dropdown-farmers {
    width: 600px;
    max-width: 600px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.react-select-dropdown-layer {
    width: 300px;
    max-width: 300px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.dashboardDrawer {
    border-radius: 0% !important;
    width: 45px !important;
}

.headerIcon {
    font-size: 16px;
    color: #ffffff;
}

/* input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #FFFFFF inset;
} */

::-webkit-scrollbar {
    width: 13px;
    height: 8px;
}

::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px #ffffff; */
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #a5a5a5;
}

::-webkit-scrollbar-thumb:hover {
    background: #7c7c7c;
}

.logo {
    padding-top: 25px;
    padding-bottom: 20px;
}

.login {
    margin: 0 auto;
    background-image: url(../../static/media/wheat.bc839fb9.png);
    background-size: 90%;
    background-repeat: repeat-x;
    background-color: #04764e;
    background-attachment: fixed;
    background-position: bottom;
    -webkit-background-size: center;
    -moz-background-size: center;
    -o-background-size: center;
    width: 100%;
    height: 100%;
    position: fixed;
    padding-top: 10%;
}

.profile {
    background-color: #126038;
    width: 284px;
    padding: 10px 0px;
    bottom: 0px;
    height: 69px;
    position: absolute;
    display: flex;
    flex-direction: row;
    padding-left: 7px;
}

.profile-avatar {
    width: 60px !important;
    height: 60px !important;
    margin: 0px 2px;
}

.userProfile-avatar {
    width: 100px !important;
    height: 100px !important;
    margin: 5px !important;
}

.MuiAvatar-root {
    align-self: center;
}

.MuiTypography-body1 {
    align-self: center;
}

.MuiPopover-paper {
    width: 10.5%;
}

.userProfile-img {
    margin: 10px;
    flex-direction: row;
    display: flex;
}

.profile-typography {
    font-weight: 500 !important;
    color: white !important;
    display: flex;
    flex-direction: column;
    padding-left: 5px;
    font-size: 18px !important;
    align-self: center;
    text-align: center !important;

}

.profile-typography2 {
    color: white !important;
    display: flex;
    flex-direction: column;
    padding-left: 5px;
    font-size: 14px !important;
    align-self: center;
    text-align: center !important;
}

.profile-typography b {
    color: black !important;
}

.listInsideMenu {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 140px);
    width: 100%;
}

.expansion-panel {
    background-color: #04764E !important;
    width: 260px;
}

[class^="TabIndicator-"],
[class*=" TabIndicator-"] {
    background-color: #58B4E5 !important;
}

.tab {
    color: #58B4E5 !important;
}

.expansion-panel-summary {
    padding: 0px 18px !important;
}

.expansion-panel-summary:hover {
    padding: 0px 14px !important;
    background-color: #058457 !important;
    background: #058457 !important;
}

.expansion-panel-summary-selected {
    padding: 0px 12px !important;
}

.expansion-panel-detail {
    padding: 1px 5px !important;
}

.expansion-panel-detail-selected {
    padding: 1px 0px !important;
}

[class^="MuiExpansionPanelSummary-expandIcon"],
[class*=" MuiExpansionPanelSummary-expandIcon"] {
    right: 15px !important;
}

.tr-head-50 {
    max-width: 50px;
}

.tr-head-60 {
    max-width: 60px;
}

.tr-head-90 {
    max-width: 90px;
}

.tr-head-100 {
    max-width: 100px;
}

.tr-head-120 {
    max-width: 120px;
}

.tr-head-150 {
    width: 150px !important;
}

.icon-summary {
    color: white !important;
    margin-right: 15px !important;
    font-size: 35px !important;
}

.icon-detail {
    color: white !important;
    margin-right: 15px !important;
    font-size: 25px !important;
}

.icon {
    display: flex;
    padding: 10px;
    font-size: 27px;
    color: #ffffff;
    width: 27px;
}

.footer {
    position: relative;
    background-color: #333333;
    width: 100%;
    height: 50px;
}

.loginText {
    text-align: center;
    vertical-align: middle;
    color: white;
    line-height: 50px;
    background-color: #126038;
    height: 50px;
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    margin: 0 auto;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.loginButton {
    background-color: #126038 !important;
    color: #ffffff !important;
    width: 80% !important;
    margin-top: 15px !important;
}

.page {
    padding: 0px;
    width: 100%;
}

.page-admin {
    margin: 70px;
}

.rt-thead.-header {
    background-color: #04764E;
    color: #FFFFFF;
}

.rt-thead.-filters {
    background-color: #3E9571;
}

.pagination-top {
    background-color: #f9f9f9;
}

.ReactTable .-pagination {
    float: right;
    width: 500px;
}

.ReactTable .rt-thead .rt-resizable-header {
    overflow: hidden;
}

.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next {
    flex: 0 1;
    text-align: center;
    /* background-color: #4FA2CD; */
}

.ReactTable .-pagination .-btn {
    color: #ffffff !important;
    padding: 0 12px !important;
}

.ReactTable .-pagination .-btn[disabled] {
    cursor: default;
}

.ReactTable .rt-table {
    display: block;
}

.rt-td {
    text-align: left;
    width: 100%;
}

.rt-resizable-header-content {
    text-align: left;
    width: 100%;
    white-space: normal;
}

.table-header {
    position: absolute;
    padding: 5px;
    z-index: 1;
}

.action-button {
    width: 30px !important;
    height: 30px !important;
    min-height: 30px !important;
    font-size: 24px !important;
    margin-left: 10px !important;
    /* background-color: #333333 !important; */
}

.action-buttonEdit {
    margin-left: 10px !important;
    /* background-color: #333333 !important; */
}

.action-button-checkbox {
    height: 30px !important;
    min-height: 30px !important;
    font-size: 24px !important;
    margin-left: 10px !important;
}

.action-button-filter {
    width: 30px !important;
    height: 30px !important;
    min-height: 30px !important;
    font-size: 24px !important;
    background-color: #333333 !important;
    right: 70px !important;
    margin-top: 10px !important;
    position: absolute !important;
}

.rt-tr-group:nth-child(2n-1) {
    background-color: #f9f9f9;
}

.rt-thead.-filters .rt-th {
    overflow: visible;
    text-align: left;
}

.pivot-without-paging {
    padding-top: 40px
}

.DateRangePicker {
    white-space: initial;
    width: 100%;
}

.roundedBox {
    width: 400px;
    height: 350px;
    margin: 0 auto;
    text-align: 'center';
    display: 'inline-block';
    border-radius: 8px !important;
}

.DateRangePickerInput__showClearDates {
    padding-right: 0px;
}

.DateRangePickerInput_clearDates {
    border-radius: 50%;
    padding: 4px;
    margin: 0px;
    background-color: #e2e2e2;
}

.numberFilterForm>* {
    width: 100%;
}

.booleanFilter {
    width: 100%;
}

.ReactTable {
    height: calc(100vh - 5px);
    border: 0;
}

.table-dialog {
    height: 700px;
}

.table-drawer {
    margin-right: 48px !important;
}

/* .DateInput {
    width: 100%;
} */

.select__multi-value__label {
    word-wrap: break-word;
    white-space: normal !important;
}

.select__value-container {
    padding: 2px 0px !important;
}

/* frozen column magic */

.frozen {
    order: -1;
    position: relative;
    left: 0;
    transition: left 0.2s;
    margin-right: 6px;
}

.dropZone-profile {
    width: 30px;
    position: relative;
    height: auto;
    margin: auto 0px 5px !important;
}

.dropZone-doc {
    width: 95%;
    position: relative;
    height: auto;
    border-width: 2px;
    border-color: rgb(102, 102, 102);
    border-style: dashed;
    border-radius: 5px;
}

.dropZone-doc-act {
    width: 95%;
    position: relative;
    height: auto;
    border-width: 2px;
    border-color: #282bee;
    border-style: solid;
    border-radius: 5px;
    background-color: #a0a39f;
}

.dropZone-doc-acpt {
    width: 95%;
    position: relative;
    height: auto;
    border-width: 2px;
    border-color: #92d442;
    border-style: solid;
    border-radius: 5px;
}

.dropZone-doc-rej {
    width: 95%;
    position: relative;
    height: auto;
    border-width: 2px;
    border-color: #f05e44;
    border-style: solid;
    border-radius: 5px;
}

.dropZone-doc-dis {
    width: 95%;
    position: relative;
    height: auto;
    border-width: 2px;
    border-color: rgb(66, 65, 65);
    border-style: dashed;
    border-radius: 5px;
}

.dropZoneIcon {
    font-size: 36px;
    color: #58B4E5;
}

.dropZoneDiv {
    text-align: center;
    vertical-align: middle;
}

.dropZone-fil {
    padding-left: 20px
}

.documentGridList {
    width: 100% !important;
    max-height: 400px !important;
}

.gps-report-panel {
    width: 800px;
    height: 550px;
}

.gps-report-map-container {
    height: 90%;
    width: 100%;
}

.profile-buttons {
    margin: 20px 17px !important;
    display: block !important;
}

.buttonsPP {
    margin: 8px 4px;
}

.documentTileBar {
    height: auto !important;
}

/* Novi css */

.responsive-dialog-title {
    background: #04764E !important;
    color: #ffffff !important;
    font-size: 22px !important;
    font-weight: 500;
    padding: 15px 24px 15px 24px !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}

.responsive-dialog-title2 {
    color: #ffffff !important;
    font-size: 22px !important;
    font-weight: 500;
    padding: 15px 24px 15px 24px !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}

.buttons {
    display: flex;
    margin-top: 15px !important;
}

.documents {
    margin-top: 7px !important;
}

.profileImage {
    padding-top: 15px !important;
}

.spiner {
    width: 100% !important;
}

.MuiMenuItem-root {
    padding-right: 210px;
}

.weather-icon-size {
    width: 20px;
    height: 32.8px;
    filter: drop-shadow(2px 2px 2px rgb(87, 87, 87));
    /* opacity: 0.9; */
}

.weather-icon-box {
    background-color: transparent;
    border: 0px;
    text-align: center;
}

.leaflet-div-icon {
    background-color: transparent;
    border: 0px;
}

.map-selects {
    margin-left: 16px;
    max-width: 92%;
    margin-bottom: 0px;
    margin-top: 0px;
}

[class^="MuiLinearProgress-bar"],
[class*="MuiLinearProgress-bar"] {
    background-color: #333333 !important;
}

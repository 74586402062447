.container{
    background-color: #f7f7f7;
}
.title{
    font-size: 16px;
    margin-left: 40px;
    padding-top:10px;
    font-weight: 400;
    color: darkgrey;
    text-align: center;
   
}
.featuredRow{
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #f7f7f7;
}
.featuredItem{
    flex: 1;
    margin: 5px 30px;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(154, 160, 185, 0.2), 0 15px 40px rgba(166, 173, 201, 0.1);
    background-color: white !important;
    
}
.feturedTop{
    width: 100%;
    display: flex;
    justify-content: space-between;

}
.featuredTitle{
    font-size: 16px;
    font-weight: 400;
    color: grey;
}
.featuredContainer{
    margin: 5px 0px;
    flex:1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid darkgrey;
}
.featuredNumber{
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 5px;
}
.featuredSub{
    font-size: 11px;
    color: grey;
}
.featuredSub2{
    font-size: 11px;
    color: black;
    font-weight: 500;
    margin-left: 3px;
}

.featuredContainerBottom{
    margin: 10px 20px;
    display: flex;
   
}
.featuredItemBottom1{
    margin: 20px 10px;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(154, 160, 185, 0.2), 0 15px 40px rgba(166, 173, 201, 0.1);
    background-color: white !important;
    height:500px;
    overflow:scroll;
    width:58%;
    
}
.featuredItemBottom2{
    margin: 20px 10px;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(154, 160, 185, 0.2), 0 15px 40px rgba(166, 173, 201, 0.1);
    background-color: white !important;
    height:500px;
    overflow:scroll;
    width:41%;
    
}

.seedingRow{
    background-color: white;
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;
    align-items: center;
 
}

.content_container {
    margin-top: 8px;
}

.content_border {
    background: #4FA2CD;
    border-radius: 20px;
    width: 100%;
    height: 2px;
}
.table {
    width: 100%;
    border-collapse: collapse;
    display: table;
}

.table_header {
    
    font-size: 12px;
    font-weight: 500;
    color: #4FA2CD;
    text-align: left;
    padding: 8px 0px 8px 8px;
    display: table-cell;
}

.table_row {
    border-bottom: solid 1px rgba(0, 0, 0, .05);
    display: table-row;
}

.table_border {
    background: #9c9c9c;
    border-radius: 20px;
    width: 100%;
    height: 2px;
}
.table_row_text {
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #333;
    text-align: left;
    padding: 8px 0px 8px 8px;
    display: table-cell;
}
.table_row_text_bold {
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: -0.3px;
    color: black;
    text-align: left;
    padding: 8px 0px 8px 8px;
    display: table-cell;
    font-weight: 500;
}

.table_row_odd {
    border-bottom: solid 1px rgba(0, 0, 0, .07);
    background: #f8f8f8;
    display: table-row;
}

.table_body {
    display: block;
    overflow: auto;
    height: 400px;
    width: 100%;
}

.table_head {
    display: table-header-group;
    vertical-align: middle;
    overflow: hidden;
}

.table_row_header {
    border-bottom: solid 1px rgba(0, 0, 0, .07);
    display: block;
}

.table_div {
    overflow-y: auto;
    max-height: 380px;
    transition: height 0.2s;
}
